// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-kapitel-1-mdx": () => import("./../../../src/pages/kapitel-1.mdx" /* webpackChunkName: "component---src-pages-kapitel-1-mdx" */),
  "component---src-pages-kapitel-2-mdx": () => import("./../../../src/pages/kapitel-2.mdx" /* webpackChunkName: "component---src-pages-kapitel-2-mdx" */),
  "component---src-pages-kapitel-3-mdx": () => import("./../../../src/pages/kapitel-3.mdx" /* webpackChunkName: "component---src-pages-kapitel-3-mdx" */),
  "component---src-pages-kapitel-4-mdx": () => import("./../../../src/pages/kapitel-4.mdx" /* webpackChunkName: "component---src-pages-kapitel-4-mdx" */),
  "component---src-pages-kapitel-5-mdx": () => import("./../../../src/pages/kapitel-5.mdx" /* webpackChunkName: "component---src-pages-kapitel-5-mdx" */)
}

