import React, { useState, useEffect } from "react";
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult
} from "react-beautiful-dnd";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { DnDProps } from "./quiz-interfaces";

const DragContainer = styled.div`
    display: flex;
    flex-direction: row;

    > * {
        width: 50%;
        margin: 0;
    }
`;
const DragList = styled.div`
    background-color: #fff;
`;

export const DNDmatch: React.FC<DnDProps> = ({
    dispatch,
    completed,
    questionIdx
}) => {
    const [data, setData] = useState(initialData);
    const [correct, setCorrect] = useState(false);

    const onDragEnd = (result: DropResult) => {
        const { destination, source, draggableId } = result;

        if (!destination) return;

        if (
            destination.droppableId === source.droppableId &&
            destination.index == source.index
        ) {
            return;
        }

        const newSitchIds = Array.from(data.order);
        newSitchIds.splice(source.index, 1);
        newSitchIds.splice(destination.index, 0, draggableId);

        setData({ ...data, order: newSitchIds });
    };

    useEffect(() => {
        const correctAnswer = correctOrder.toString() === data.order.toString();

        dispatch({
            type: "QUESTION_ANSWERED",
            payload: {
                which: questionIdx,
                correct: correctAnswer
            }
        });

        setCorrect(correctAnswer);
    }, [data.order]);

    return (
        <div>
            <h4
                css={css`
                    color: ${completed && !correct ? "#D41418" : "#000"};
                `}
            >
                Matcha ihop pusselbitarna nedan så att alla meningar blir
                korrekta
            </h4>
            <DragContainer>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable
                        droppableId="situation-drop"
                        isDropDisabled={completed}
                    >
                        {provided => (
                            <DragList
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {data.situations
                                    .sort(
                                        (a, b) =>
                                            data.order.indexOf(a.id) -
                                            data.order.indexOf(b.id)
                                    )
                                    .map(sitch => (
                                        <DragItem
                                            key={sitch.id}
                                            id={sitch.id}
                                            content={sitch.content}
                                            index={data.order.indexOf(sitch.id)}
                                            completed={completed}
                                        />
                                    ))}
                                {provided.placeholder}
                            </DragList>
                        )}
                    </Droppable>
                </DragDropContext>
                <div>
                    {answerList.map((answer, idx) => (
                        <Item key={`answer-${idx}`} type="end">
                            <CardContent css={cardContentStyle(null)}>
                                {answer}
                            </CardContent>
                        </Item>
                    ))}
                </div>
            </DragContainer>
        </div>
    );
};

interface DragItemProps {
    content: string;
    id: string;
    index: number;
    completed: boolean;
}

interface ItemProps {
    type: "start" | "end";
    completed?: boolean;
    correct?: boolean;
}

const itemType = (props: ItemProps) =>
    props.type === "start"
        ? css`
              /* padding: 2rem 2rem 2rem 3rem; */
              border-width: 1px 1px 1px 8px;
              margin-right: 20px;
              &:after {
                  content: "";
                  border-color: transparent transparent transparent
                      ${!props.completed
                          ? "#fff"
                          : props.correct
                          ? "#459A33"
                          : "#D41418"};
                  border-style: solid;
                  border-width: 9px;
                  width: 0;
                  height: 0;
                  position: absolute;
                  top: 50%;
                  left: 100%;
                  transform: translateY(-50%);
              }

              &:before {
                  content: "";
                  border-color: transparent transparent transparent #000;
                  border-style: solid;
                  border-width: 10px;
                  width: 0;
                  height: 0;
                  position: absolute;
                  top: 50%;
                  left: calc(100% + 0.5px);
                  transform: translateY(-50%);
              }
          `
        : css`
              border-width: 1px;
          `;

const Item = styled(Card)<ItemProps>`
    height: 160px;
    margin: 0 0 1rem 0;
    border-style: solid;
    border-color: #000;
    background-color: #fff;
    position: relative;
    overflow: visible !important;
    ${itemType}

    @media screen and (min-width: 680px) {
        height: 100px;
    }
`;

const cardContentStyle = (correct: boolean | null) => css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${correct === null
        ? "#fff"
        : correct
        ? "#459A33"
        : "#D41418"};
    color: ${correct === null ? "#000" : "#fff"};
`;

const DragItem: React.FC<DragItemProps> = ({
    content,
    id,
    index,
    completed
}) => {
    const [correct, setCorrect] = useState(false);

    useEffect(() => {
        setCorrect(index === correctOrder.indexOf(id));
    }, [index]);

    return (
        <Draggable draggableId={id} index={index} isDragDisabled={completed}>
            {provided => (
                <Item
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    type="start"
                    completed={completed}
                    correct={correct}
                >
                    <CardContent
                        css={cardContentStyle(completed ? correct : null)}
                    >
                        <DragHandleIcon
                            css={css`
                                opacity: ${completed ? 0 : 1};
                                margin-right: 1rem;
                            `}
                        />
                        {content}
                    </CardContent>
                </Item>
            )}
        </Draggable>
    );
};

const initialData = {
    situations: [
        {
            id: "sit-1",
            content: "Om du upptäcker ett pågående övergrepp..."
        },
        {
            id: "sit-2",
            content:
                "Om du misstänker att ett barn utsätts för sexuella övergrepp..."
        },
        {
            id: "sit-3",
            content: "Om ett barn berättar för dig om övergrepp..."
        }
    ],
    order: ["sit-2", "sit-3", "sit-1"]
};

const answerList = [
    "...ska du omedelbart ringa 112.",
    "...ska du försöka prata med barnet och visa att du bryr dig och finns där.",
    "...ska du inte lova att inte berätta för någon."
];

const correctOrder = ["sit-1", "sit-2", "sit-3"];
