import React from "react";
import styled from "@emotion/styled";

interface IProps {
    direction?: "left" | "right" | "up" | "down";
    size?: number;
    color?: string;
}

interface ArrowSVGProps {
    size?: number;
    color?: string;
}

const StyledArrow = styled("svg")<ArrowSVGProps>`
    height: ${props => props.size}px;
    fill: ${props => props.color};
    transition: transform 150ms ease-out;
`;

export const Arrow: React.FC<IProps> = ({
    direction = "right",
    size = 10,
    color = "#000"
}) => {
    switch (direction) {
        case "left":
            return ArrowLeft(size, color);
        case "right":
            return ArrowRight(size, color);
        case "up":
            return ArrowUp(size, color);
        case "down":
            return ArrowDown(size, color);
    }
};

const ArrowDown = (size: number, color: string) => (
    <StyledArrow
        size={size}
        color={color}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 13 6"
    >
        <path d="M6.49987275 4.13397441L11.78483479.5 12.5 1.37426775 6.49986464 5.5.5 1.3743604l.7151756-.87426061z" />
    </StyledArrow>
);

const ArrowUp = (size: number, color: string) => (
    <StyledArrow
        size={size}
        color={color}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 13 6"
    >
        <path d="M6.50012725 1.86602559L1.21516521 5.5.5 4.62573225 6.50013536.5 12.5 4.6256396l-.7151756.87426061z" />
    </StyledArrow>
);

const ArrowLeft = (size: number, color: string) => (
    <StyledArrow
        size={size}
        color={color}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 5 12"
    >
        <path d="M1.36602559 5.99987275L5 11.28483479 4.12573225 12 0 5.99986464 4.1256396 0l.87426061.7151756z" />
    </StyledArrow>
);

const ArrowRight = (size: number, color: string) => (
    <StyledArrow
        size={size}
        color={color}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 5 12"
    >
        <path d="M3.63397441 6.00012725L0 .71516521.87426775 0 5 6.00013536.87436041 12l-.87426062-.7151756z" />
    </StyledArrow>
);
