import React, { useReducer } from "react";
import { css } from "@emotion/core";
import { QuizState, QuizDispatch, ActionWithPayload } from "./quiz-interfaces";
import {
    MultipleChoiceWithRadios,
    MultipleChoiceWithChecks
} from "./multiple-choice";
import { DNDmatch } from "./dnd-match";
import multipleChoiceData from "./content/multiple-choice.json";
import selectMultipleData from "./content/select-multiple.json";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";

const codes = {
    answered: "QUESTION_ANSWERED",
    completed: "QUIZ_COMPLETED"
};

const initialState = (questions: number): QuizState => ({
    touched: Array(questions).fill(false),
    correct: Array(questions).fill(false),
    finished: false
});

const quizReducer = (state: QuizState, action: QuizDispatch) => {
    const stateCopy = { ...state };
    switch (action.type) {
        case codes.answered:
            // Always set touched to true
            stateCopy.touched[
                (action as ActionWithPayload).payload.which
            ] = true;
            // Correct boolean set at question component
            stateCopy.correct[
                (action as ActionWithPayload).payload.which
            ] = (action as ActionWithPayload).payload.correct;

            return stateCopy;
        case codes.completed:
            return {
                ...state,
                finished: true
            };
        default:
            return state;
    }
};

export const Quiz: React.FC = () => {
    const [{ touched, correct, finished }, dispatch] = useReducer(
        quizReducer,
        initialState(4)
    );

    const { width, height } = useWindowSize();

    return (
        <div>
            {multipleChoiceData.map((q, idx) => (
                <MultipleChoiceWithRadios
                    key={`q${idx}`}
                    question={q.title}
                    answers={q.answers}
                    solution={q.correct_single}
                    questionIdx={idx}
                    completed={finished}
                    dispatch={dispatch}
                />
            ))}
            <DNDmatch
                dispatch={dispatch}
                completed={finished}
                questionIdx={2}
            />
            <MultipleChoiceWithChecks
                question={selectMultipleData.title}
                answers={selectMultipleData.answers}
                solution={selectMultipleData.correct_multiple}
                questionIdx={3}
                completed={finished}
                dispatch={dispatch}
            />

            <div
                css={css`
                    width: 100%;
                    margin: 2rem 0;
                    display: flex;
                    justify-content: center;
                `}
            >
                {!finished && (
                    <Button
                        variant="contained"
                        onClick={() => {
                            if (touched.indexOf(false) === -1) {
                                dispatch({ type: "QUIZ_COMPLETED" });
                            }
                        }}
                        color="primary"
                        disabled={touched.indexOf(false) !== -1}
                    >
                        Färdig
                    </Button>
                )}
                {finished && correct.indexOf(false) === -1 && (
                    <>
                        <Confetti
                            width={width}
                            height={height}
                            css={css`
                                position: fixed !important;
                            `}
                        />
                        <Alert css={alertStyling} severity="success">
                            {"Du fick alla rätt! "}
                            <span role="img">🎉</span>
                        </Alert>
                    </>
                )}
                {finished && correct.indexOf(false) !== -1 && (
                    <Alert css={alertStyling} severity="error">
                        <div>
                            {"Du svarade fel på "}
                            {correct.filter(el => el === false).length}
                            {" fråg"}
                            {correct.filter(el => el === false).length === 1
                                ? "a. "
                                : "or. "}
                            <span role="img">😔</span>
                        </div>
                        <div>
                            {
                                "Gå gärna igenom frågorna igen och läs de rätta svaren."
                            }
                        </div>
                    </Alert>
                )}
            </div>
        </div>
    );
};

const alertStyling = css`
    * {
        margin-top: 0;
    }
`;
