import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { Arrow } from "./arrow";

interface LinkProp {
    chapter: string;
    title: string;
    link: string;
}

interface IProps {
    previous?: LinkProp;
    next?: LinkProp;
}

interface DirectionProp {
    left?: boolean;
}

const NavContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const NavItem = styled(Link)<DirectionProp>`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    text-decoration: none;
    margin: 0;
    max-width: 50%;

    svg {
        fill: rgb(28, 107, 186);
        margin: auto 0;
    }

    &:hover * {
        color: rgb(20, 20, 20);
        fill: rgb(20, 20, 20);
    }

    &:hover svg {
        transform: translateX(${props => (props.left ? "-0.5rem" : "0.5rem")});
    }
`;

const NavText = styled("div")<DirectionProp>`
    margin: 1rem;
    text-align: ${props => (props.left ? "left" : "right")};

    small,
    em {
        line-height: 1.2rem;
        color: rgb(28, 107, 186);
    }
`;

const Filler = styled.div`
    flex-grow: 1;
`;

export const Navigation: React.FC<IProps> = ({ previous, next }) => {
    return (
        <NavContainer>
            {previous ? (
                <NavItem to={`/${previous.link}`} left>
                    <Arrow size={12} direction="left" />
                    <NavText left>
                        <small>{previous.chapter}</small>
                        <strong>{previous.title}</strong>
                    </NavText>
                </NavItem>
            ) : (
                <Filler />
            )}
            {next ? (
                <NavItem to={`/${next.link}`}>
                    <NavText>
                        <small>{next.chapter}</small>
                        <strong>{next.title}</strong>
                    </NavText>
                    <Arrow size={12} direction="right" />
                </NavItem>
            ) : (
                <Filler />
            )}
        </NavContainer>
    );
};
