import React, { useState } from "react";
import { css } from "@emotion/core";
import { MCRProps, MCCProps } from "./quiz-interfaces";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";

export const MultipleChoiceWithRadios: React.FC<MCRProps> = ({
    question,
    answers,
    solution,
    questionIdx,
    completed,
    dispatch
}) => {
    const [picked, setPicked] = useState<number | undefined>();

    return (
        <div>
            <h4
                css={css`
                    color: ${completed && picked !== solution
                        ? "#D41418"
                        : "initial"};
                `}
            >
                {question}
            </h4>
            {answers.map((answer, idx) => (
                <div
                    key={`MCR${questionIdx}-a${idx}`}
                    onClick={() => {
                        if (!completed) {
                            setPicked(idx);
                            dispatch({
                                type: "QUESTION_ANSWERED",
                                payload: {
                                    which: questionIdx,
                                    correct: idx === solution
                                }
                            });
                        }
                    }}
                    css={css`
                        cursor: ${completed ? "default" : "pointer"};
                        display: flex;
                        flex-direction: row;
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <Radio
                            checked={picked === idx}
                            css={css`
                                * {
                                    margin-top: 0;
                                }
                            `}
                            disabled={completed}
                        />
                    </div>
                    <p
                        css={colorCorrect(
                            completed,
                            solution === idx,
                            picked === idx
                        )}
                    >
                        {answer}
                    </p>
                </div>
            ))}
        </div>
    );
};

const colorCorrect = (
    completed: boolean,
    correct: boolean,
    picked: boolean
) => {
    if (picked) {
        if (completed && correct) {
            return css`
                font-weight: 500;
                color: #459a33;
            `;
        } else if (completed && !correct) {
            return css`
                font-weight: 500;
                color: #d41418;
            `;
        }
    } else if (completed && correct) {
        return css`
            font-weight: 500;
            color: #459a33;
        `;
    }
};

export const MultipleChoiceWithChecks: React.FC<MCCProps> = ({
    question,
    answers,
    solution,
    questionIdx,
    completed,
    dispatch
}) => {
    const [picked, setPicked] = useState<number[]>([]);

    return (
        <div>
            <h4
                css={css`
                    color: ${completed &&
                    picked.toString() !== solution.toString()
                        ? "#D41418"
                        : "initial"};
                `}
            >
                {question}
            </h4>
            {answers.map((answer, idx) => (
                <div
                    key={`MCC${questionIdx}-a${idx}`}
                    onClick={() => {
                        if (!completed) {
                            const updated = new Set(picked);

                            if (updated.has(idx)) {
                                updated.delete(idx);
                            } else {
                                updated.add(idx);
                            }

                            const updatedArr = Array.from(updated).sort();

                            setPicked(updatedArr);

                            dispatch({
                                type: "QUESTION_ANSWERED",
                                payload: {
                                    which: questionIdx,
                                    correct:
                                        updatedArr.toString() ===
                                        solution.toString()
                                }
                            });
                        }
                    }}
                    css={css`
                        cursor: ${completed ? "default" : "pointer"};
                        display: flex;
                        flex-direction: row;
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <Checkbox
                            checked={picked.indexOf(idx) !== -1}
                            css={css`
                                * {
                                    margin-top: 0;
                                }
                            `}
                            disabled={completed}
                        />
                    </div>
                    <p
                        css={colorCorrect(
                            completed,
                            solution.indexOf(idx) !== -1,
                            picked.indexOf(idx) !== -1
                        )}
                    >
                        {answer}
                    </p>
                </div>
            ))}
        </div>
    );
};
