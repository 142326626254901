import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { Arrow } from "./arrow";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BookIcon from "@material-ui/icons/Book";

interface IProps {
    url: string;
    title: string;
    type: "chapter" | "quiz";
}

const LinkComp = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 3rem;
    text-decoration: none;

    svg:nth-of-type(2) {
        fill: rgb(28, 107, 186);
        margin: 0 0 0 1rem;
    }

    &:hover {
        svg:nth-of-type(2) {
            fill: rgb(20, 20, 20);
            transform: translateX(0.5rem);
        }
        strong {
            color: rgb(20, 20, 20);
        }
    }

    strong {
        color: rgb(28, 107, 186);
    }

`;

const iconStyle = css`
    color: rgb(28, 107, 186);
    width: 20px;
    overflow: auto;
    margin: 0 1rem 0;
    opacity: 0.75;

    * {
        margin: 0;
    }
`;

export const ChapterLink: React.FC<IProps> = ({ url, title, type }) => {
    return (
        <LinkComp to={`/${url}`}>
            {type === "chapter" && <BookIcon css={iconStyle} />}
            {type === "quiz" && <AssignmentIcon css={iconStyle} />}
            <strong>{title}</strong>
            <Arrow direction="right" size={12} />
        </LinkComp>
    );
};
